<template>
    <div class="content">
        <div class="contont_img">
            <img src="../../assets/img/recruit_01.png" alt="" />
            <img src="../../assets/img/recruit_02.png" alt="" @click="showPopView()" />
            <img src="../../assets/img/recruit_03.png" alt="" />
        </div>
        <div class="verify_box" v-show="showPop">
            <div class="verifybox">
                <div class="pop_title">
                    <div
                        style="margin-left: 15px; margin-bottom: 10px; margin-top: 10px; font-size: 16px; font-weight: 500; color: #666666;">
                        请输入个人信息</div>
                    <div class="closeBtn" @click="showPop = false"></div>
                </div>
                <van-field class="pop_field" v-model="form.name" name="name" label="" placeholder="请输入您的姓名"
                    :rules="[]" />
                <van-field class="pop_field" v-model="form.phone" name="phone" label="" placeholder="请输入您的手机号码"
                    :rules="[]" />
                <div class="pop_sure" @click="getData"> 提交 </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            form: {
                phone: "",
                name: "",
            },
            hasError: false,
            msg: "",
            showPop: false,
        };
    },
    methods: {
        showPopView() {
            this.form.phone = "",
                this.form.name = "",
                this.showPop = true
        },
        validatePhone(val) {
            let reg = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
            return reg.test(val);
        },
        getData() {
            let name = this.form.name;
            let phone = this.form.phone;
            if (name == "") {
                this.$dialog.alert({
                    title: "错误提示",
                    message: "输入姓名",
                });
                return;
            }
            if (phone == "") {
                this.$dialog.alert({
                    title: "错误提示",
                    message: "输入手机号",
                });
                return;
            }
            if (!this.validatePhone(phone)) {
                this.$dialog.alert({
                    title: "错误提示",
                    message: "请输入正确的手机号",
                });
                return;
            }

            this.showPop = false
            this.$axios({
                url: this.$requests.tech.getRecruitData,
                method: "post",
                data: {
                    techPhone: this.form.phone,
                    techName: this.form.name,
                },
            }).then((res) => {
                if (res.data.code !== 200) {
                let message =  res.data.message || "请求失败";
                this.$dialog.alert({
                    title: "错误提示",
                    message: message,
                });
              } else{
                 this.$dialog.alert({
                    title: "招聘信息提交成功",
                    message: "感谢您的信赖，我们将尽快与您联系，期待您能加入到我们的团队",
                });
              }
            }).catch((e) => {});
        }
    },
    mounted() { },
};
</script>

<style lang="less" scoped>
.contont_img {
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }
}

.verify_box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.5s;

}

.verifybox {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #e4e7eb;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
}

.pop_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.closeBtn {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background: url(../../assets/img/close.png) no-repeat center;
    background-size: 100% 100%;
}

.pop_field {
    border: 0.5px solid #999999;
    margin-top: 5px;
    margin-left: 3vw;
    margin-bottom: 10px;
    width: 84vw;
    border-radius: 5px;
}

.pop_sure {
    margin-top: 20px;
    margin-left: 13vw;
    width: 64vw;
    height: 30px;
    background: #EE4908;
    border: 0.5px solid #A67D1A;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
    color: #FFFFFE;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
</style>
